import React, { useContext } from 'react';
import Context from '../context/Context';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import cx from 'classnames';

export default ({
	imageOne = {},
	imageTwo = {},
	index,
	title,
	artist,
	exhibition,
}) => {
	const { setFeaturedImage, setLightBoxOpen } = useContext(Context);

	return (
		<section
			className={cx(
				'grid-container contained mt5 mb5 mb10--md mt10--md',
				{
					'mt20--lg mb20--lg': index > 0 && !exhibition,
				}
			)}
		>
			<div className="row">
				{imageOne && (
					<aside className="col c6--md">
						{imageOne?.artwork && (
							<picture>
								<source
									srcSet={`${imageOne.artwork.url}?w=2000&auto=format&q=100`}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={`${imageOne.artwork.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${imageOne.artwork.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										title && artist
											? `${title} — ${artist}`
											: 'Image courtesy of the artist'
									}
									className="db x b--black pointer"
									onClick={() => {
										setFeaturedImage(imageOne);
										setTimeout(() => {
											setLightBoxOpen(true);
										}, 100);
									}}
									src={`${imageOne.artwork.url}?w=600&auto=format`}
								/>
							</picture>
						)}
						<div className="mt1 mt2--lg sans--14 sans--18--md rich-text">
							{imageOne.title && <p>{imageOne.title}</p>}
							{imageOne.description && (
								<BlockContent
									blocks={imageOne.description}
									serializers={richText}
								/>
							)}
						</div>
					</aside>
				)}
				{imageTwo && (
					<aside className="col c6--md mt5 mt0--md">
						{imageTwo?.artwork && (
							<picture>
								<source
									srcSet={`${imageTwo.artwork.url}?w=2000&auto=format&q=100`}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={`${imageTwo.artwork.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${imageTwo.artwork.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										title && artist
											? `${title} — ${artist}`
											: 'Image courtesy of the artist'
									}
									className="db x b--black pointer"
									onClick={() => {
										setFeaturedImage(imageTwo);
										setTimeout(() => {
											setLightBoxOpen(true);
										}, 100);
									}}
									src={`${imageTwo.artwork.url}?w=600&auto=format`}
								/>
							</picture>
						)}
						<div className="mt1 mt2--lg sans--14 sans--18--md rich-text">
							{imageTwo.title && <p>{imageTwo.title}</p>}
							{imageTwo.description && (
								<BlockContent
									blocks={imageTwo.description}
									serializers={richText}
								/>
							)}
						</div>
					</aside>
				)}
			</div>
		</section>
	);
};
