import React, { useContext } from 'react';
import Context from '../context/Context';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import useHover from '../util/useHover';

export default ({
	index,
	artwork,
	title,
	artist,
	description,
	portrait,
	exhibition,
}) => {
	const [hoverRef, isHovered] = useHover();
	const { setFeaturedImage, setLightBoxOpen } = useContext(Context);

	return (
		<section
			ref={hoverRef}
			className={cx(
				'grid-container contained mt5 mb5 mb10--md mt10--md',
				{
					'mb20--lg mt20--lg': index > 0 && !exhibition,
				}
			)}
		>
			<div className="row align--center">
				<div
					className={cx('col', {
						'c10--lg': !portrait,
						'c5--lg': portrait,
					})}
				>
					<picture className="db x b--black artwork--full-container">
						<source
							srcSet={`${
								artwork?.asset?.url || artwork.url
							}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${
								artwork?.asset?.url || artwork.url
							}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${
								artwork?.asset?.url || artwork.url
							}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={`${title} — ${artist}`}
							className={cx('db artwork--full pointer', {
								hovered: isHovered,
							})}
							onClick={() => {
								setFeaturedImage({
									artwork,
									title,
									artist,
									description,
									portrait,
									exhibition,
								});
								setTimeout(() => {
									setLightBoxOpen(true);
								}, 100);
							}}
							src={`${
								artwork?.asset?.url || artwork.url
							}?w=600&auto=format`}
						/>
					</picture>
					{(title || description) && (
						<div className="mt1 mt2--lg ">
							{title && (
								<p className="sans--14 sans--18--md">{title}</p>
							)}

							{description && (
								<div className="mt1 mt2--lg sans--14 sans--18--md rich-text">
									<BlockContent
										blocks={description}
										serializers={richText}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
